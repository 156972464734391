import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { toast } from "sonner";
import { useEffect, useState } from "react";

import { fetchSegmentos } from "@/services/segmentoService";
import { fetchCausas } from "@/services/causaService";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { MdiPlusBoxMultiple } from "@/components/icons/MdiPlusBoxMultiple";
import { addAccionToSiniestro, createBien } from "@/services/siniestroService";

const formSchema = z.object({
  incident_id: z.number(),
  instance_id: z.number(),
  brand: z.string().optional(),
  segment_id: z.number(),
  cause_id: z.number(),
  item_type: z.string().optional(),
  model: z.string().optional().nullable(),
  serial: z.string().optional().nullable(),
  accesories: z.string().optional().nullable(),
  coveredDetails: z.string().optional(),
  observations: z.string().nullable().optional(),
  receiptNumber: z.string().nullable().optional(),
  deliveryNumber: z.string().nullable().optional(),
  claimedValue: z.number().nullable().optional(),
  recognizedValue: z.number().nullable().optional(),
  repairedRecognizedValue: z.string().nullable().optional(),
  otherObservations: z.string().nullable().optional(),
});

interface AddBienDialogProps {
  siniestroId: number;
  onAddBien: (nuevoBien: any) => void;
  onDetailsSaved: (bienId: number, updatedDetails: any) => void;
  disabled?: boolean;
}

export function AddBienDialog({
  siniestroId,
  onAddBien,
  onDetailsSaved,
  disabled = false,
}: AddBienDialogProps) {
  const [segments, setSegments] = useState<Segmento[]>([]);
  const [causes, setCauses] = useState<Causa[]>([]);
  const [isOpen, setIsOpen] = useState(false);

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      incident_id: siniestroId,
      instance_id: 1,
      brand: "",
      segment_id: "",
      cause_id: null,
      item_type: "",
      model: null,
      serial: null,
      accesories: null,
      coveredDetails: null,
      observations: null,
      receiptNumber: null,
      deliveryNumber: null,
      claimedValue: null,
      recognizedValue: null,
      repairedRecognizedValue: null,
      otherObservations: null,
    },
  });

  useEffect(() => {
    const fetchSegmentsData = async () => {
      try {
        const segmentsData = await fetchSegmentos();
        setSegments(segmentsData.segmentos);
      } catch (error) {}
    };

    void fetchSegmentsData();
  }, []);

  useEffect(() => {
    const fetchCausesData = async () => {
      try {
        const causesData = await fetchCausas();
        setCauses(causesData);
      } catch (error) {
        setCauses([]);
      }
    };

    void fetchCausesData();
  }, []);

  const onSubmit = async (data: any) => {
    try {
      const formattedData = {
        ...data,
        repairedRecognizedValue: data.repairedRecognizedValue || null,
        coveredDetails: data.coveredDetails || null,
        observations: data.observations || null,
        otherObservations: data.otherObservations || null,
        receiptNumber: data.receiptNumber || null,
        deliveryNumber: data.deliveryNumber || null,
        claimedValue: data.claimedValue || 0,
        recognizedValue: data.recognizedValue || 0,
      };

      const nuevoBien = await createBien(formattedData);

      // Asegúrate de que el nuevo bien tenga todas las propiedades necesarias
      const bienConDetalles = {
        ...nuevoBien,
        itemType: nuevoBien.item_type_name || "No definido",
        brandName: nuevoBien.brand_name || "No definido",
        segmentName: nuevoBien.segmentName || "No definido",
        causeName: nuevoBien.causeName || "No definido",
        resolutionName: nuevoBien.resolutionName || "No definido",
        tipoPagoName: nuevoBien.tipoPagoName || "No definido",
      };

      onAddBien(bienConDetalles);

      onDetailsSaved(nuevoBien.id, {
        claimedValue: nuevoBien.claimedValue,
        recognizedValue: nuevoBien.recognizedValue,
        repairedRecognizedValue: nuevoBien.repairedRecognizedValue,
      });

      await addAccionToSiniestro(siniestroId, 25, null, nuevoBien.id);

      toast.success("Bien creado correctamente");

      form.reset({
        incident_id: siniestroId,
        instance_id: 1,
        brand: "",
        segment_id: "",
        cause_id: null,
        item_type: "",
        model: null,
        serial: null,
        accesories: null,
        coveredDetails: null,
        observations: null,
        receiptNumber: null,
        deliveryNumber: null,
        claimedValue: null,
        recognizedValue: null,
        repairedRecognizedValue: null,
        otherObservations: null,
      });

      setIsOpen(false); // Cierra el modal después de agregar el bien
    } catch (error) {
      toast.error("Error al crear bien");
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button variant="green" className="align-right" disabled={disabled}>
          <MdiPlusBoxMultiple className="w-5 h-5 mr-2" />
          Agregar bien
        </Button>
      </DialogTrigger>
      <DialogContent className="h-[600px] overflow-auto">
        <DialogHeader>
          <DialogTitle>Agregar un nuevo bien</DialogTitle>
          <DialogDescription>
            Actualiza los detalles aquí y haz clic en guardar cuando termines.
          </DialogDescription>
        </DialogHeader>

        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="space-y-4 bg-white dark:bg-gray-700 p-8 rounded-lg max-w-md w-full"
          >
            <Controller
              control={form.control}
              name="segment_id"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Segmento</FormLabel>
                  <Select
                    onValueChange={(value) => {
                      const segmentSelected = segments.find(
                        (segment) => segment.NOMBRE_SEGMENTO === value
                      );
                      if (segmentSelected) {
                        field.onChange(Number(segmentSelected.CODIGO_SEGMENTO));
                      }
                    }}
                    value={
                      field.value
                        ? segments.find(
                            (segment) =>
                              segment.CODIGO_SEGMENTO === Number(field.value)
                          )?.NOMBRE_SEGMENTO
                        : ""
                    }
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {segments.map((segment) => (
                        <SelectItem
                          key={segment.CODIGO_SEGMENTO}
                          value={segment.NOMBRE_SEGMENTO}
                        >
                          {segment.NOMBRE_SEGMENTO}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />

            <Controller
              control={form.control}
              name="item_type"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Tipo de bien</FormLabel>
                  <Input
                    {...field}
                    value={field.value}
                    onChange={(e) => field.onChange(e.target.value)}
                    placeholder="Ingresar tipo de bien"
                  />
                  <FormMessage />
                </FormItem>
              )}
            />

            <Controller
              control={form.control}
              name="brand"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Marca</FormLabel>
                  <Input
                    {...field}
                    value={field.value}
                    onChange={(e) => field.onChange(e.target.value)}
                    placeholder="Ingresar marca"
                  />
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="model"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Modelo</FormLabel>
                  <Input {...field} value={field.value ?? ""} />
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="serial"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Serial</FormLabel>
                  <Input {...field} value={field.value ?? ""} />
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="accesories"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Accesorios</FormLabel>
                  <Input {...field} value={field.value ?? ""} />
                  <FormMessage />
                </FormItem>
              )}
            />

            <Controller
              control={form.control}
              name="cause_id"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Causa</FormLabel>
                  <Select
                    onValueChange={(value) => {
                      const causaSelected = causes.find(
                        (causa) => causa.name === value
                      );
                      if (causaSelected) {
                        field.onChange(Number(causaSelected.id));
                      }
                    }}
                    value={
                      field.value
                        ? causes.find((causa) => causa.id === field.value)?.name
                        : ""
                    }
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {causes.map((causa) => (
                        <SelectItem key={causa.id} value={causa.name}>
                          {causa.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="coveredDetails"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Detalle de cobertura</FormLabel>
                  <Input {...field} value={field.value ?? ""} />
                  <FormMessage />
                </FormItem>
              )}
            />
            <DialogFooter>
              <Button type="submit" variant="default">
                Guardar
              </Button>
              <DialogClose asChild>
                <Button variant="secondary">Cerrar</Button>
              </DialogClose>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
