const API_BASE_URL =
  import.meta.env.MODE === "production"
    ? import.meta.env.VITE_API_URL_PROD
    : import.meta.env.VITE_API_URL_DEV;

import { apiFetch } from "./siniestroService";

export const fetchImagesByBienId = async (
  bienId: number
): Promise<string[]> => {
  const response = await apiFetch(`/api/bienes/${bienId}/images`);
  if (!response.ok) {
    throw new Error(`Failed to fetch images for bien with ID ${bienId}`);
  }
  const images = await response.json();
  return images.map(
    (image: string) =>
      `${API_BASE_URL}${image.startsWith("/") ? "" : "/"}${image}`
  );
};

export const deleteImage = async (
  bienId: number,
  imageUrl: string
): Promise<void> => {
  const response = await apiFetch(
    `/api/bienes/${bienId}/images?imageUrl=${imageUrl}`,
    {
      method: "DELETE",
    }
  );
  if (!response.ok) {
    throw new Error(
      `Failed to delete image ${imageUrl} for bien with ID ${bienId}`
    );
  }
};

export const analyzeImageWithGemini = async (
  imageUrls: string[]
): Promise<any> => {
  try {
    const response = await apiFetch(`/api/bienes/analyze-image`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ imageUrls }),
    });

    if (!response.ok) {
      throw new Error(`Error al analizar imágenes: ${response.statusText}`);
    }

    const result = await response.json();

    // Si todas las imágenes fueron documentos o no analizables
    if (result.brand === "No analizable") {
      console.warn("Ninguna imagen analizable encontrada");
    }

    return result;
  } catch (error) {
    console.error("Error en el análisis de imágenes:", error);
    throw error;
  }
};
