import React, { Suspense, useState } from "react";
import Spinner from "../ui/spinner";
import {
  Menubar,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarSeparator,
  MenubarTrigger,
} from "../ui/menubar";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
} from "../ui/dropdown-menu";
import { Button } from "../ui/button";
import NotFoundPage from "./NotFoundPage";
import { useAuth } from "@/contexts/AuthContext";
import UsersList from "../users/UsersList";
const CompaniesList = React.lazy(
  async () => await import("../companies/CompaniesList")
);
const CausasList = React.lazy(async () => await import("../causas/CausasList"));
const InstanciasList = React.lazy(
  async () => await import("../instancias/InstanciasList")
);
const ResolucionesList = React.lazy(
  async () => await import("../resoluciones/ResolucionesList")
);
const SegmentosList = React.lazy(
  async () => await import("../segmentos/SegmentosList")
);
const TiposPagoList = React.lazy(
  async () => await import("../tipo-pagos/TipoPagoList")
);
const PredefinedHonorariosList = React.lazy(
  () => import("../honorarios/PredefinedHonorariosList")
);
const PlantillasObservacionesList = React.lazy(
  () => import("../plantillas-observaciones/PlantillasObservacionesList")
);
const GastosControl = React.lazy(() => import("./GastosControl"));

// Define un tipo para los componentes
type ComponentType =
  | "users"
  | "companies"
  | "causas"
  | "instancias"
  | "resoluciones"
  | "segmentos"
  | "tiposPago"
  | "predefinedHonorarios"
  | "plantillas"
  | "gastosControl"
  | null;

const UsersAndCompaniesMenubar = () => {
  const [activeComponent, setActiveComponent] =
    useState<ComponentType>("users");
  const { roles } = useAuth();
  const hasRole2 = roles.includes(2);

  if (!hasRole2) {
    return <NotFoundPage />;
  }
  const components = {
    users: UsersList,
    companies: CompaniesList,
    causas: CausasList,
    instancias: InstanciasList,
    resoluciones: ResolucionesList,
    segmentos: SegmentosList,
    tiposPago: TiposPagoList,
    predefinedHonorarios: PredefinedHonorariosList,
    plantillas: PlantillasObservacionesList,
    gastosControl: GastosControl,
  };

  const renderActiveComponent = () => {
    // Comprobación explícita de que activeComponent no es null
    if (activeComponent !== null) {
      const Component = components[activeComponent];
      return (
        <Suspense fallback={<Spinner />}>
          <Component />
        </Suspense>
      );
    }
    return null;
  };

  return (
    <div>
      {/* Menú desplegable para móviles */}
      <div className="sm:hidden flex justify-center py-2">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline">Menú parametría</Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-56">
            <DropdownMenuItem
              onSelect={() => {
                setActiveComponent("users");
              }}
            >
              Usuarios
            </DropdownMenuItem>
            <DropdownMenuItem
              onSelect={() => {
                setActiveComponent("companies");
              }}
            >
              Compañías
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuItem
              onSelect={() => {
                setActiveComponent("instancias");
              }}
            >
              Instancias
            </DropdownMenuItem>
            <DropdownMenuItem
              onSelect={() => {
                setActiveComponent("causas");
              }}
            >
              Causas
            </DropdownMenuItem>
            <DropdownMenuItem
              onSelect={() => {
                setActiveComponent("segmentos");
              }}
            >
              Segmentos
            </DropdownMenuItem>
            <DropdownMenuItem
              onSelect={() => {
                setActiveComponent("resoluciones");
              }}
            >
              Resoluciones
            </DropdownMenuItem>
            <DropdownMenuItem
              onSelect={() => {
                setActiveComponent("tiposPago");
              }}
            >
              Tipos de pago
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuItem
              onSelect={() => {
                setActiveComponent("predefinedHonorarios");
              }}
            >
              Honorarios predefinidos
            </DropdownMenuItem>
            <DropdownMenuItem
              onSelect={() => {
                setActiveComponent("plantillas");
              }}
            >
              Plantillas de informes técnicos
            </DropdownMenuItem>
            <DropdownMenuItem
              onSelect={() => {
                setActiveComponent("gastosControl");
              }}
            >
              Control de gastos
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>

      {/* Menubar para desktop */}
      <div className="hidden sm:flex justify-center bg-opacity-60 backdrop-blur-md">
        <Menubar className="px-2 lg:px-4">
          {/* Menú Usuarios */}
          <MenubarMenu>
            <MenubarTrigger className="font-bold">Usuarios</MenubarTrigger>
            <MenubarContent>
              <MenubarItem
                onSelect={() => {
                  setActiveComponent("users");
                }}
              >
                Listado de usuarios
              </MenubarItem>
            </MenubarContent>
          </MenubarMenu>

          {/* Menú Compañías */}
          <MenubarMenu>
            <MenubarTrigger>Compañías</MenubarTrigger>
            <MenubarContent>
              <MenubarItem
                onSelect={() => {
                  setActiveComponent("companies");
                }}
              >
                Listado de compañías
              </MenubarItem>
            </MenubarContent>
          </MenubarMenu>

          <MenubarMenu>
            <MenubarTrigger>Honorarios</MenubarTrigger>
            <MenubarContent>
              <MenubarItem
                onSelect={() => {
                  setActiveComponent("predefinedHonorarios");
                }}
              >
                Honorarios predefinidos
              </MenubarItem>
            </MenubarContent>
          </MenubarMenu>

          <MenubarMenu>
            <MenubarTrigger>Plantillas</MenubarTrigger>
            <MenubarContent>
              <MenubarItem
                onSelect={() => {
                  setActiveComponent("plantillas");
                }}
              >
                Plantillas de informes
              </MenubarItem>
            </MenubarContent>
          </MenubarMenu>

          {/* Menú Bienes */}
          <MenubarMenu>
            <MenubarTrigger>Bienes</MenubarTrigger>
            <MenubarContent>
              <MenubarItem
                onSelect={() => {
                  setActiveComponent("instancias");
                }}
              >
                Instancias
              </MenubarItem>
              <MenubarItem
                onSelect={() => {
                  setActiveComponent("causas");
                }}
              >
                Causas
              </MenubarItem>
              <MenubarSeparator />
              <MenubarItem
                onSelect={() => {
                  setActiveComponent("segmentos");
                }}
              >
                Segmentos
              </MenubarItem>
              <MenubarItem
                onSelect={() => {
                  setActiveComponent("resoluciones");
                }}
              >
                Resoluciones
              </MenubarItem>
              <MenubarSeparator />
              <MenubarItem
                onSelect={() => {
                  setActiveComponent("tiposPago");
                }}
              >
                Tipos de pago
              </MenubarItem>
            </MenubarContent>
          </MenubarMenu>

          {/* Menú Siniestros */}

          <MenubarMenu>
            <MenubarTrigger>Configuración</MenubarTrigger>
            <MenubarContent>
              <MenubarItem
                onSelect={() => {
                  setActiveComponent("gastosControl");
                }}
              >
                Control de gastos
              </MenubarItem>
            </MenubarContent>
          </MenubarMenu>
        </Menubar>
      </div>

      <div className="mt-4 rounded-lg p-4">{renderActiveComponent()}</div>
    </div>
  );
};

export default UsersAndCompaniesMenubar;
