import { useEffect, useState } from "react";
import { useAuth } from "@/contexts/AuthContext";
import { useSiniestroStore } from "@/stores/siniestroStore";
import { SiniestroColumns } from "./SiniestrosColumns";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { SkeletonRow } from "../ui/SkeletonRow";
import { DataTablePagination } from "./DataTablePagination";
import { toast } from "sonner";
import { TbFilterOff } from "react-icons/tb";
import {
  flexRender,
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  type ColumnDef,
  type ColumnSort,
} from "@tanstack/react-table";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { NumbersFilter } from "./filters/NumbersFilter";
import { CompanyFilter } from "./filters/CompanyFilter";
import { TramitadorFilter } from "./filters/TramitadoresFilter";
import { EstadoFilter } from "./filters/EstadosFilter";
import { ProveedorFilter } from "./filters/ProveedoresFilter";
import { IonSearch } from "../icons/IonSearch";
import { ArrowUpDown } from "lucide-react";
import { fetchSiniestros } from "@/services/siniestroService";
import useUserStore from "@/stores/userStore";
import { Skeleton } from "../ui/skeleton";

interface DataTableProps {
  currentPage: number;
  headerRight?: React.ReactNode;
  data: Siniestro[];
}

export function SiniestroDataTable({ headerRight }: DataTableProps) {
  const { editingSiniestroId, setIsUpdatingState, isUpdatingState } =
    useSiniestroStore();
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [sorting, setSorting] = useState<ColumnSort[]>([]);
  const { username, roles, isAuthenticated, isAuthChecked } = useAuth();
  const [myCasesOnly, setMyCasesOnly] = useState<boolean>(false);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [fetchedData, setFetchedData] = useState<Siniestro[]>([]);
  const [pageCount, setPageCount] = useState(0);
  const [total, setTotal] = useState<number>(0);
  const [sortDirection, setSortDirection] = useState("ASC");
  const [sortBy, setSortBy] = useState<"ultimaAccionFecha" | "id">(
    "ultimaAccionFecha"
  );
  const userRoles = Array.isArray(roles) ? roles : [];
  const hasRole7 = userRoles.includes(7);
  const [selectedDni, setSelectedDni] = useState<string>("");
  const [selectedTramitador, setSelectedTramitador] = useState<
    string | undefined
  >(undefined);
  const [selectedEstado, setSelectedEstado] = useState<string | undefined>(
    undefined
  );
  const [selectedCompanyId, setSelectedCompanyId] = useState<
    number | undefined
  >(undefined);
  const [selectedFilter, setSelectedFilter] = useState<string | undefined>(
    undefined
  );
  const [selectedProveedor, setSelectedProveedor] = useState<
    string | undefined
  >(undefined);
  const [selectedElectroReceiptNumber, setSelectedElectroReceiptNumber] =
    useState<string | undefined>(undefined);
  const [appliedDni, setAppliedDni] = useState<string>("");
  const [appliedTramitador, setAppliedTramitador] = useState<
    string | undefined
  >(undefined);
  const [appliedEstado, setAppliedEstado] = useState<string | undefined>(
    undefined
  );
  const [appliedCompanyId, setAppliedCompanyId] = useState<number | undefined>(
    undefined
  );
  const [appliedFilter, setAppliedFilter] = useState<string | undefined>(
    undefined
  );
  const [appliedProveedor, setAppliedProveedor] = useState<string | undefined>(
    undefined
  );
  const [initialLoad, setInitialLoad] = useState(true);
  const [searchTrigger, setSearchTrigger] = useState(0);

  const toggleSortDirection = () => {
    setSortDirection((prevSortDirection) =>
      prevSortDirection === "DESC" ? "ASC" : "DESC"
    );
  };

  const toggleSortBy = () => {
    setSortBy((prev) =>
      prev === "ultimaAccionFecha" ? "id" : "ultimaAccionFecha"
    );
  };

  const fetchData = async () => {
    if (initialLoad) {
      return;
    }

    setIsLoadingData(true);
    setIsUpdatingState(true);

    try {
      const dniOrCuit =
        appliedFilter === "dni" && appliedDni ? appliedDni : undefined;

      const siniestroNumber =
        appliedFilter === "siniestroNumber" && appliedDni
          ? String(appliedDni)
          : undefined;
      const insuredName =
        appliedFilter === "insuredName" && appliedDni
          ? String(appliedDni)
          : undefined;
      const policyNumber =
        appliedFilter === "policyNumber" && appliedDni
          ? String(appliedDni)
          : undefined;
      const receiptNumber =
        appliedFilter === "receiptNumber" && appliedDni
          ? String(appliedDni)
          : undefined;
      const deliveryNumber =
        appliedFilter === "deliveryNumber" && appliedDni
          ? String(appliedDni)
          : undefined;
      const electroReceiptNumber =
        appliedFilter === "electroReceiptNumber" ? appliedDni : undefined;

      const responsibleName: string | undefined = myCasesOnly
        ? username ?? undefined
        : appliedTramitador;

      const data = await fetchSiniestros(
        pagination.pageIndex,
        pagination.pageSize,
        dniOrCuit,
        appliedCompanyId,
        responsibleName,
        siniestroNumber,
        insuredName,
        policyNumber,
        sortBy,
        sortDirection,
        appliedEstado,
        appliedProveedor,
        receiptNumber,
        deliveryNumber,
        electroReceiptNumber
      );

      setFetchedData(data.siniestros);
      setPageCount(data.pageCount);
      setTotal(data.total);
    } catch (error) {
      toast.error("Error al cargar los datos");
    } finally {
      setIsLoadingData(false);
      setIsUpdatingState(false);
    }
  };

  useEffect(() => {
    if (
      isAuthChecked &&
      isAuthenticated &&
      (!initialLoad || searchTrigger > 0)
    ) {
      fetchData();
    }
  }, [
    isAuthChecked,
    isAuthenticated,
    initialLoad,
    pagination.pageIndex,
    pagination.pageSize,
    appliedCompanyId,
    appliedTramitador,
    appliedFilter,
    appliedDni,
    sortDirection,
    appliedEstado,
    appliedProveedor,
    myCasesOnly,
    username,
    searchTrigger,
    sortBy,
  ]);
  const handleUpdateFilter = async () => {
    setAppliedDni(selectedDni);
    setAppliedTramitador(selectedTramitador);
    setAppliedEstado(selectedEstado);
    setAppliedCompanyId(selectedCompanyId);
    setAppliedFilter(selectedFilter);
    setAppliedProveedor(selectedProveedor);
    setSelectedElectroReceiptNumber(selectedElectroReceiptNumber);
    setPagination((prevPagination) => ({ ...prevPagination, pageIndex: 0 }));
    setInitialLoad(false);
    setSearchTrigger((prevTrigger) => prevTrigger + 1);
  };

  const updateSiniestro = (id: number, updatedFields: Partial<Siniestro>) => {
    setFetchedData((prevFetchedData) =>
      prevFetchedData.map((siniestro) =>
        siniestro.id === id ? { ...siniestro, ...updatedFields } : siniestro
      )
    );
  };

  const handleClearFilters = () => {
    // Limpiar filtros seleccionados
    setSelectedDni("");
    setSelectedTramitador(undefined);
    setSelectedEstado(undefined);
    setSelectedCompanyId(undefined);
    setSelectedFilter(undefined);
    setSelectedProveedor(undefined);
    setSelectedElectroReceiptNumber(undefined);
    setMyCasesOnly(false);

    // Limpiar filtros aplicados
    setAppliedDni("");
    setAppliedTramitador(undefined);
    setAppliedEstado(undefined);
    setAppliedCompanyId(undefined);
    setAppliedFilter(undefined);
    setAppliedProveedor(undefined);

    // Resetear paginación y estados relacionados
    setPagination({ pageIndex: 0, pageSize: 10 });
    setPageCount(0);
    setTotal(0);
    setFetchedData([]);

    // Trigger para recargar datos
    setInitialLoad(true);
    setSearchTrigger((prev) => prev + 1);
  };

  const columns = SiniestroColumns(updateSiniestro);

  const table = useReactTable({
    data: fetchedData,
    columns: columns as ColumnDef<Siniestro, any>[],
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    onPaginationChange: (newPagination) => {
      setPagination(newPagination);
    },
    state: {
      sorting,
      pagination,
    },
    manualPagination: true,
    manualSorting: true,
    pageCount: pageCount,
  });

  const setCurrentPage = useUserStore((state) => state.setCurrentPage);

  useEffect(() => {
    setCurrentPage(table.getState().pagination.pageIndex);
    setPagination(table.getState().pagination);
  }, [table.getState().pagination.pageIndex, setCurrentPage]);

  return (
    <div className="w-full flex justify-center">
      <div className="w-[98%] max-w-[1800px]">
        <div className="mb-4 flex justify-center">
          <div className="w-full flex flex-col md:flex-row items-center justify-center gap-2">
            <div className="w-full flex flex-col md:flex-row items-center justify-center flex-wrap gap-2">
              <Input
                placeholder="Buscar..."
                value={selectedDni}
                className="w-[200px] md:max-w-sm"
                onChange={(e) => setSelectedDni(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleUpdateFilter();
                  }
                }}
              />
              <NumbersFilter
                value={selectedFilter}
                onChange={(value) => setSelectedFilter(value)}
                onSearch={handleUpdateFilter}
              />
              {!hasRole7 && (
                <CompanyFilter
                  onChange={(value) =>
                    setSelectedCompanyId(value ? Number(value) : undefined)
                  }
                  selectedValue={selectedCompanyId?.toString()}
                />
              )}
              <EstadoFilter
                onChange={(value) => setSelectedEstado(value)}
                selectedValue={selectedEstado}
              />
              <TramitadorFilter
                onChange={(value) => setSelectedTramitador(value)}
                selectedValue={selectedTramitador}
                onMyCasesChange={setMyCasesOnly}
              />
              {!roles.includes(11) || hasRole7 ? (
                <ProveedorFilter
                  onChange={(value) => setSelectedProveedor(value)}
                  selectedValue={selectedProveedor}
                />
              ) : null}
              <div className="flex gap-2 justify-center">
                <TooltipProvider delayDuration={100}>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button
                        onClick={handleUpdateFilter}
                        disabled={isLoadingData}
                      >
                        <IonSearch />
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>Buscar</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
                <TooltipProvider delayDuration={100}>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button
                        onClick={toggleSortDirection}
                        disabled={isLoadingData}
                      >
                        <ArrowUpDown className="h-4 w-4" />
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>Cambiar dirección de ordenamiento</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
                <TooltipProvider delayDuration={100}>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button
                        variant="outline"
                        onClick={toggleSortBy}
                        disabled={isLoadingData}
                      >
                        {sortBy === "ultimaAccionFecha" ? (
                          <span className="text-sm">Por última acción</span>
                        ) : (
                          <span className="text-sm">Por fecha de creación</span>
                        )}
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>
                        Cambiar orden:{" "}
                        {sortBy === "ultimaAccionFecha"
                          ? "última acción"
                          : "fecha de creación"}
                      </p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
                <TooltipProvider delayDuration={100}>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button
                        variant="outline"
                        onClick={handleClearFilters}
                        disabled={isLoadingData}
                      >
                        <TbFilterOff className="h-4 w-4" />
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>Limpiar filtros</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>
            </div>
            {headerRight && (
              <div className="md:ml-auto flex items-center">{headerRight}</div>
            )}
          </div>
        </div>

        <div className="rounded-md border w-full">
          <div className="overflow-x-auto w-full">
            {initialLoad ? (
              <p className="text-center py-4 text-gray-500">
                Utilice los filtros para buscar siniestros
              </p>
            ) : isLoadingData ? (
              <Table className="w-full">
                <TableHeader>
                  <TableRow>
                    {columns.map((_column, index) => (
                      <TableHead key={index}>
                        <Skeleton className="h-4 w-full" />
                      </TableHead>
                    ))}
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {Array.from({ length: pagination.pageSize }).map(
                    (_, index) => (
                      <SkeletonRow key={index} columnsCount={columns.length} />
                    )
                  )}
                </TableBody>
              </Table>
            ) : fetchedData.length === 0 ? (
              <p className="text-center py-4 text-gray-500">
                No se encontraron resultados para la búsqueda
              </p>
            ) : (
              <Table className="w-full">
                <TableHeader>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <TableRow key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <TableHead key={header.id}>
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </TableHead>
                      ))}
                    </TableRow>
                  ))}
                </TableHeader>
                <TableBody>
                  {table.getRowModel().rows.map((row) => {
                    const isRowBeingEdited =
                      isUpdatingState && row.original.id === editingSiniestroId;

                    return (
                      <TableRow
                        key={row.id}
                        data-state={
                          row.getIsSelected() ? "selected" : undefined
                        }
                      >
                        {isRowBeingEdited ? (
                          <TableCell colSpan={columns.length} className="p-2">
                            <Skeleton className="w-full h-8" />
                          </TableCell>
                        ) : (
                          row.getVisibleCells().map((cell) => (
                            <TableCell key={cell.id}>
                              {flexRender(cell.column.columnDef.cell, {
                                ...cell.getContext(),
                                updateSiniestro,
                              })}
                            </TableCell>
                          ))
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            )}
          </div>
        </div>
        <DataTablePagination
          table={table}
          pageCount={pageCount}
          total={total}
        />
      </div>
    </div>
  );
}
