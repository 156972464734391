import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
  useParams,
} from "react-router-dom";
import Navbar from "./components/navbar";
import "./index.css";
import HomePage from "./components/pages/HomePage";
import { Toaster } from "sonner";
import { ThemeProvider } from "./components/theme-provider";
import NotFoundPage from "./components/pages/NotFoundPage";
import UsersAndCompaniesMenubar from "./components/pages/UsersAndCompaniesMenubar";
import SiniestrosList from "./components/siniestros/SiniestrosList";
import SiniestroDetails from "./components/siniestros/SiniestroDetails/SiniestroDetails";
import { AseguradoForm } from "./components/siniestros/forms/AseguradoForm";
import { DireccionForm } from "./components/siniestros/forms/DireccionForm";
import { SiniestroForm } from "./components/siniestros/forms/SiniestroForm";
import { BienesForm } from "./components/siniestros/forms/BienesForm";
import SiniestroLayout from "./components/siniestros/forms/SiniestroLayout";
import FacturasList from "./components/facturacion/FacturasList";
import GastosProveedoresList from "./components/proveedores/ProveedoresList";
import { AuthProvider, useAuth } from "./contexts/AuthContext";
import DashboardPage from "./components/dashboard/Page";
import NotificacionesPage from "./components/notificaciones/Notifications";
import OldSiniestrosTable from "./components/siniestros/OldSiniestrosTable";
import SiniestrosStatsPage from "./components/dashboard/SiniestrosStatsPage";
import SearchPage from "./components/siniestros/SearchStrosCompanies";
import { useEffect } from "react";
import Spinner from "./components/ui/spinner";
import { GastosProvider } from "./contexts/GastosContext";

function SiniestroWrapper() {
  return (
    <SiniestroLayout>
      <Outlet />
    </SiniestroLayout>
  );
}

function SiniestroDetailsWrapper() {
  const { siniestroId } = useParams();

  if (siniestroId == null) {
    return <NotFoundPage />;
  }

  return <SiniestroDetails siniestroId={parseInt(siniestroId)} />;
}

const FullLayout: React.FC = () => {
  return (
    <div>
      <Outlet />
      <Toaster richColors position="top-center" />
    </div>
  );
};

const DefaultLayout: React.FC = () => {
  const { isDisabled, isAuthChecked, checkAuth } = useAuth();

  useEffect(() => {
    checkAuth();
  }, []);

  // Esperar a que la autenticación esté lista
  if (!isAuthChecked) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <div>
      {!isDisabled && (
        <div className="py-4 shadow-md">
          <Navbar />
        </div>
      )}
      <header className="App-header mt-10">
        <Outlet />
      </header>
      <Toaster richColors position="top-center" />
    </div>
  );
};

function App() {
  return (
    <Router>
      <AuthProvider>
        <ThemeProvider defaultTheme="light" storageKey="vite-ui-theme">
          <GastosProvider>
            <Routes>
              <Route path="/" element={<FullLayout />}>
                <Route index element={<HomePage />} />
              </Route>
              <Route path="/" element={<DefaultLayout />}>
                <Route path="list" element={<UsersAndCompaniesMenubar />} />
                <Route path="facturacion" element={<FacturasList />} />
                <Route path="proveedores" element={<GastosProveedoresList />} />
                <Route path="dashboard" element={<DashboardPage />} />
                <Route
                  path="dashboard/stats"
                  element={<SiniestrosStatsPage />}
                />{" "}
                <Route path="notificaciones" element={<NotificacionesPage />} />
                <Route path="siniestros" element={<SiniestrosList />} />
                <Route
                  path="siniestros/antiguos"
                  element={<OldSiniestrosTable />}
                />
                <Route path="siniestros/busqueda" element={<SearchPage />} />
                <Route
                  path="siniestros/:siniestroId/detalles"
                  element={<SiniestroDetailsWrapper />}
                />
                {/* Ruta para el layout de configuraciones y sus formularios */}
                <Route path="/nuevo-siniestro" element={<SiniestroWrapper />}>
                  <Route path="nuevo-asegurado" element={<AseguradoForm />} />
                  <Route path="nueva-direccion" element={<DireccionForm />} />
                  <Route path="nuevo-siniestro" element={<SiniestroForm />} />
                  <Route path="nuevo-bien" element={<BienesForm />} />
                </Route>
              </Route>

              {/* Ruta de comodín para manejar URLs no definidas */}
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </GastosProvider>
        </ThemeProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
