import { apiFetch } from "./siniestroService";

export async function getGastosHabilitados(): Promise<boolean> {
  const response = await apiFetch("/api/settings/gastos-habilitados");
  const data = await response.json();
  return data.enabled;
}

export async function updateGastosHabilitados(enabled: boolean): Promise<void> {
  await apiFetch("/api/settings/gastos-habilitados", {
    method: "PUT",
    body: JSON.stringify({ enabled }),
  });
}
