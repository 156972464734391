import React, { createContext, useContext, useState, useEffect } from "react";
import {
  getGastosHabilitados,
  updateGastosHabilitados,
} from "@/services/settingsService";
import { toast } from "sonner";

interface GastosContextType {
  gastosHabilitados: boolean;
  toggleGastosHabilitados: () => Promise<void>;
  isLoading: boolean;
}

const GastosContext = createContext<GastosContextType | undefined>(undefined);

export function GastosProvider({ children }: { children: React.ReactNode }) {
  const [gastosHabilitados, setGastosHabilitados] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchInitialState = async () => {
      try {
        const enabled = await getGastosHabilitados();
        setGastosHabilitados(enabled);
      } catch (error) {
        console.error("Error fetching gastos state:", error);
        toast.error("Error al cargar la configuración de gastos");
      } finally {
        setIsLoading(false);
      }
    };

    void fetchInitialState();
  }, []);

  const toggleGastosHabilitados = async () => {
    try {
      await updateGastosHabilitados(!gastosHabilitados);
      setGastosHabilitados(!gastosHabilitados);
      toast.success(
        `Carga de gastos ${!gastosHabilitados ? "habilitada" : "deshabilitada"}`
      );
    } catch (error) {
      console.error("Error updating gastos state:", error);
      toast.error("Error al actualizar la configuración de gastos");
    }
  };

  return (
    <GastosContext.Provider
      value={{ gastosHabilitados, toggleGastosHabilitados, isLoading }}
    >
      {children}
    </GastosContext.Provider>
  );
}

export function useGastos() {
  const context = useContext(GastosContext);
  if (context === undefined) {
    throw new Error("useGastos must be used within a GastosProvider");
  }
  return context;
}
