import { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import {
  addHistorialMessage,
  apiFetch,
  checkIfUserIsTramitador,
  fetchHistorialSiniestro,
  getUserInfo,
  notifyAboutComment,
} from "@/services/siniestroService";
import { toast } from "sonner";
import { ReloadIcon } from "@radix-ui/react-icons";
import { Switch } from "../ui/switch";
import { useAuth } from "@/contexts/AuthContext";
import { addAccionToSiniestro } from "@/services/siniestroService";

interface TextAreaHistorialProps {
  siniestroId: number;
  onHistorialUpdated: (updatedHistorial: HistorialSiniestro[]) => void;
  hasRole7: boolean;
}

interface HistorialEntry {
  id: number;
  incident_id: string;
  user_id: number;
  text: string;
  insertedAt: string;
  userName: string;
  userRoles: number[];
}

export function TextAreaHistorial({
  siniestroId,
  onHistorialUpdated,
  hasRole7,
}: TextAreaHistorialProps) {
  const { roles } = useAuth();
  const [message, setMessage] = useState("");
  const [userId, setUserId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [notifyCompany, setNotifyCompany] = useState(false);
  const [notifyCompanySupervisor, setNotifyCompanySupervisor] = useState(true);
  const [isTramitador, setIsTramitador] = useState(false);
  const [companyHasCommented, setCompanyHasCommented] = useState(false);
  const hasRole11 = roles.includes(11);
  const isSupervisor = roles.includes(18);

  useEffect(() => {
    const fetchUserInfoAndHistorial = async () => {
      try {
        const userInfo = await getUserInfo();
        setUserId(userInfo.id);
        const tramitadorCheck = await checkIfUserIsTramitador(
          userInfo.id,
          siniestroId
        );
        setIsTramitador(tramitadorCheck);

        // Obtener el historial y verificar comentarios de la compañía
        const historial = await fetchHistorialSiniestro(siniestroId);

        const hasCompanyComment = historial.some((entry: HistorialEntry) => {
          return entry.userRoles.includes(7);
        });

        setCompanyHasCommented(hasCompanyComment);
      } catch (error) {
        console.error(
          "Error al obtener información del usuario o historial:",
          error
        );
      }
    };

    void fetchUserInfoAndHistorial();
  }, [siniestroId]);

  const handleSendMessage = async () => {
    if (message.trim().length === 0 || !userId || isLoading) return;

    try {
      setIsLoading(true);

      await addHistorialMessage(siniestroId, userId, message);
      toast.success("Mensaje enviado");
      setMessage("");

      // Agregar la nueva acción si el usuario tiene rol 11 y el responsable es 144
      if (hasRole11) {
        const response = await apiFetch(`/api/siniestros/${siniestroId}`);
        const data = await response.json();
        if (data.siniestro[0].responsible === 144) {
          await addAccionToSiniestro(siniestroId, 606, null, null);
        }
      }

      const updatedHistorial = await fetchHistorialSiniestro(siniestroId);
      onHistorialUpdated(updatedHistorial);

      const isTramitador = await checkIfUserIsTramitador(userId, siniestroId);

      if (isTramitador && notifyCompany) {
        // Si es tramitador y quiere notificar a la compañía
        await notifyAboutComment(siniestroId, message, userId, true);
        toast.success(
          "Se ha notificado a los usuarios de la compañía que han comentado en este siniestro"
        );
      } else if (hasRole7) {
        // Si es usuario de compañía
        await notifyAboutComment(siniestroId, message, userId, false);
      } else if (isSupervisor && notifyCompanySupervisor) {
        await notifyAboutComment(siniestroId, message, userId, false, false);
        toast.success(
          "Se ha notificado a los usuarios de la compañía sobre su respuesta"
        );
      }
    } catch (error) {
      console.error("Error en handleSendMessage:", error);
      toast.error("Error al enviar el mensaje");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="grid w-full gap-2">
      <Textarea
        placeholder="Escribe un mensaje"
        value={message}
        onChange={(e) => {
          setMessage(e.target.value);
        }}
        className="min-h-[100px]" // This sets a minimum height
        rows={4} // This sets the initial number of visible text lines
      />

      {isTramitador && !hasRole7 && companyHasCommented && (
        <div className="flex items-center space-x-2">
          <Switch
            id="notify-company"
            checked={notifyCompany}
            onCheckedChange={setNotifyCompany}
          />
          <label htmlFor="notify-company">Notificar a la compañía</label>
        </div>
      )}

      {isSupervisor && (
        <div className="flex items-center space-x-2">
          <Switch
            id="notify-company-supervisor"
            checked={notifyCompanySupervisor}
            onCheckedChange={setNotifyCompanySupervisor}
          />
          <label htmlFor="notify-company-supervisor">
            Notificar a la compañía
          </label>
        </div>
      )}

      {isLoading ? (
        <Button disabled>
          <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
          Enviando...
        </Button>
      ) : (
        <Button onClick={handleSendMessage}>Enviar mensaje</Button>
      )}
    </div>
  );
}
